import React from 'react'
import { navigate } from 'gatsby'
import palette from '../../static/img/Palette.png'
import {
  squareSVGT,
  circleSVGT,
  distressedsquareSVGT,
  distressedcircleSVGT,
  ruffledcircleSVGT,
  scallopedrectangleSVGT,
  curvedsquareSVGT,
  bookmarkSVGT
} from '../../static/svgtext'
import OutsideClickHandler from 'react-outside-click-handler'

export var ContainerSVGT = circleSVGT
export var ContainerChoice = "Circle"
export var MainFont = 'IM Fell English'
export var SubFont = 'Poppins'

export var HomeColor = "#CE8531"
export var BeliefsColor = "#D63340"
export var EventsColor = "#D69432"
export var LiveColor = "#994680"
export var SermonsColor = "#4E8558"
export var YouthColor = "#4659A3"
export var GivingColor = "#46A8A3"
export var ContactColor = "#BD6B42"
export var ThirdPage = "Livestream (YouTube)"
export var FourthPage = "Sermons"
export var YouthName = "Youth"
export var SixthPage = 'Tithe.ly'

var CurrentPage = null
var SX = null
var SY = null
var Prompt = true

const Sampler = class extends React.Component {  
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      samplerActiveClass: '',
      colorTest: 'blue',
      PageZone: 'none',
      ThirdPageSelect: 'Livestream (YouTube)',
      FourthPageSelect: 'Sermons',
      YouthNameSelect: 'Youth',
      SixthPageSelect: 'Tithe.ly'
    }
  }
  
  forceRender = () => {
    this.setState()
  }

  toggleHamburger = () => {

    if (Prompt == true) {
      Prompt = false
    }

    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the sampler accordingly
        this.state.active
          ? this.setState({
              samplerActiveClass: 'is-active',
            })
          : this.setState({
              samplerActiveClass: '',
            })
      }
    )
  }

  render() {

    if (typeof window !== "undefined") {
      if (CurrentPage !== window.location.pathname) {
        CurrentPage = window.location.pathname
        window.scrollTo(null, 0)
      }
      if (SX !== null) {
        const SXX = SX
        const SYY = SY
        window.history.scrollRestoration = "manual"
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 0)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 1)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 2)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 3)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 4)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 5)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 6)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 7)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 8)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 9)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 10)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 11)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 12)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 13)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 14)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 15)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 16)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 17)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 18)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 19)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 20)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 21)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 22)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 23)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 24)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 25)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 26)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 27)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 28)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 29)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 30)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 31)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 32)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 33)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 34)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 35)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 36)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 37)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 38)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 39)
        window.setTimeout(() => { window.scrollTo(SXX, SYY); SX = null; SY = null }, 40)
      }
    }
    
    return (
      <nav
        className="sampler is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        {Prompt == true ? 
          <div>
            <div className='sampler-prompt-backing'></div>
            <div className='sampler-arrow-backing'></div>
            <div className='sampler-arrow'/>
            <div className='sampler-prompt'>
              Click here to open the sampler menu!
            </div>
          </div>
        : null}
        <div className="sampler-div">
          <OutsideClickHandler
            onOutsideClick={() => {
              if (this.state.active) {
                this.toggleHamburger()
              }
            }}
          >
            <div className="sampler-brand" style={{justifyContent: 'flex-end'}}>
              
              <button onClick={() => this.toggleHamburger()} className={`sampler-button ${this.state.samplerActiveClass}`} title="Sampler" id="imgPalette">
                <img src={palette} alt="Cross" style={{ width: '26px', alignSelf: 'right'}} />
              </button>

              {/* Hamburger menu */}
              <div
                className={`sampler-burger burger ${this.state.samplerActiveClass}`}
                style={{marginLeft: '0'}}
                data-target="samplerBox"
              >
                <span />
                <span />
                <span />
              </div>
            </div>

            <div
              id="samplerBox"
              className={`sampler-menu ${this.state.samplerActiveClass}`}
              tabIndex="0"
            >
              <div className="sampler-box" style={{
                marginTop: '58px',
                marginLeft: '10px'
              }}>
                {/* COLORS */}
                <label style={{fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px'}}>
                  <input style={{ marginRight: '5px' }} type='color' id='MainColor' name='Main Color' defaultValue={HomeColor} onInput={evt => { HomeColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY}} />
                  Main Color<br/>
                </label>
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='BeliefsColor' name='Beliefs Color' defaultValue={BeliefsColor} onInput={evt => { BeliefsColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  Beliefs Color<br />
                </label>
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='EventsColor' name='Events Color' defaultValue={EventsColor} onInput={evt => { EventsColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  Events Color<br />
                </label>
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='LiveColor' name='Live Color' defaultValue={LiveColor} onInput={evt => { LiveColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  {ThirdPage === "Livestream (Facebook)" || ThirdPage === "Livestream (YouTube)" ? "Live" : ThirdPage === "Video (Facebook)" || ThirdPage === "Video (YouTube)" ? "Video" : "History"} Color<br />
                </label>
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='SermonsColor' name='Sermons Color' defaultValue={SermonsColor} onInput={evt => { SermonsColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  {FourthPage} Color<br />
                </label>
                {YouthName === "Removed" ? null : 
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='YouthColor' name='Youth Color' defaultValue={YouthColor} onInput={evt => { YouthColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  {YouthName} Color<br />
                </label>
                }
                {SixthPage === "Removed" ? null : 
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='GivingColor' name='Giving Color' defaultValue={GivingColor} onInput={evt => { GivingColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  Giving Color<br />
                </label>
                }
                <label style={{ fontFamily: MainFont, fontSize: MainFont === 'Playfair Display SC' ? '17px' : '20px' }}>
                  <input style={{ marginRight: '5px' }} type='color' id='ContactColor' name='Contact Color' defaultValue={ContactColor} onInput={evt => { ContactColor = evt.target.value; navigate(); SX = window.scrollX; SY = window.scrollY }} />
                  Contact Color<br /><br />
                </label>

                <label style={{ fontFamily: MainFont, fontSize: '20px' }}>
                  Container Style: <br />
                  <div style={{ fontFamily: SubFont, fontSize: '15px' }}>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Circle' checked={ ContainerSVGT === circleSVGT ? true : false} onChange={() => { ContainerSVGT = circleSVGT; ContainerChoice = "Circle"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Circle</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Square' checked={ContainerSVGT === squareSVGT ? true : false} onChange={() => { ContainerSVGT = squareSVGT; ContainerChoice = "Square"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Square</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Bookmark' checked={ContainerSVGT === bookmarkSVGT ? true : false} onChange={() => { ContainerSVGT = bookmarkSVGT; ContainerChoice = "Bookmark"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Bookmark</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Distressed Circle' checked={ContainerSVGT === distressedcircleSVGT ? true : false} onChange={() => { ContainerSVGT = distressedcircleSVGT; ContainerChoice = "DistressedC"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Distressed Circle</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Distressed Square' checked={ContainerSVGT === distressedsquareSVGT ? true : false} onChange={() => { ContainerSVGT = distressedsquareSVGT; ContainerChoice = "DistressedS"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Distressed Square</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Ruffled' checked={ContainerSVGT === ruffledcircleSVGT ? true : false} onChange={() => { ContainerSVGT = ruffledcircleSVGT; ContainerChoice = "Ruffled"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Ruffled Circle</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Scalloped' checked={ContainerSVGT === scallopedrectangleSVGT ? true : false} onChange={() => { ContainerSVGT = scallopedrectangleSVGT; ContainerChoice = "Scallop"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Scalloped Rectangle</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioc' id='Curved' checked={ContainerSVGT === curvedsquareSVGT ? true : false} onChange={() => { ContainerSVGT = curvedsquareSVGT; ContainerChoice = "Curved"; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Curved Square</div></span></label>
                  </div>
                </label>
                <br />
                
                {/* MAIN FONT (HEADING FONT) */}
                <label style={{ fontFamily: MainFont, fontSize: '20px' }}>
                  Heading Font: <br />
                  <div style={{ fontSize: '15px' }}>

                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioh' 
                      checked={MainFont === 'IM Fell English' ? true : false}
                      id='IM Fell English' 
                      onChange={() => { MainFont = 'IM Fell English'; navigate(); SX = window.scrollX; SY = window.scrollY }} 
                    />
                      <span className="checkmark">
                        <div className="sampler-text" style={{ fontFamily: 'IM Fell English'}}>IM Fell English</div>
                      </span>
                    </label>

                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioh' 
                      checked={MainFont === 'Bahnschrift' ? true : false}
                      id='Bahnschrift' 
                      onChange={() => { MainFont = 'Bahnschrift'; navigate(); SX = window.scrollX; SY = window.scrollY }}
                    />
                      <span className="checkmark">
                        <div className="sampler-text" style={{ fontFamily: 'Bahnschrift' }}>Bahnschrift</div>
                      </span>
                    </label>

                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioh' 
                      checked={MainFont === 'Maven Pro' ? true : false}
                      id='Maven Pro' 
                      onChange={() => { MainFont = 'Maven Pro'; navigate(); SX = window.scrollX; SY = window.scrollY }}
                    />
                      <span className="checkmark">
                        <div className="sampler-text" style={{ fontFamily: 'Maven Pro' }}>Maven Pro</div>
                      </span>
                    </label>

                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioh' 
                      checked={MainFont === 'Permanent Marker' ? true : false}
                      id='Permanent Marker' 
                      onChange={() => { MainFont = 'Permanent Marker'; navigate(); SX = window.scrollX; SY = window.scrollY }}
                    />
                      <span className="checkmark">
                        <div className="sampler-text" style={{ fontSize: MainFont === 'Permanent Marker' ? null : '12px', marginTop: MainFont==='Permanent Marker'? null: '1px', fontFamily: 'Permanent Marker' }}>Permanent Marker</div>
                      </span>
                    </label>

                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioh' 
                      checked={MainFont === 'Dosis' ? true : false}
                      id='Dosis' 
                      onChange={() => { MainFont = 'Dosis'; navigate(); SX = window.scrollX; SY = window.scrollY }}
                    />
                      <span className="checkmark">
                        <div className="sampler-text" style={{ fontFamily: 'Dosis' }}>Dosis</div>
                      </span>
                    </label>

                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioh'
                      checked={MainFont === 'Playfair Display SC' ? true : false}
                      id='Playfair Display SC'
                      onChange={() => { MainFont = 'Playfair Display SC'; navigate(); SX = window.scrollX; SY = window.scrollY }}
                    />
                      <span className="checkmark">
                        <div className="sampler-text" style={{ fontFamily: 'Playfair Display SC' }}>Playfair Display SC</div>
                      </span>
                    </label>
                  </div>
                </label>
                <br />

                {/* THIRD PAGE OPTIONS (MEDIA)*/}
                <label style={{ fontFamily: MainFont, fontSize: '20px' }}>
                  Third Page: <br />
                  <div style={{ fontFamily: SubFont, fontSize: '15px' }}>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio3' id='Livestream (YouTube)' checked={ThirdPage === 'Livestream (YouTube)' ? true : false} onChange={() => { ThirdPage = 'Livestream (YouTube)'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Livestream (YouTube)</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio3' id='Livestream (Facebook)' checked={ThirdPage === 'Livestream (Facebook)' ? true : false} onChange={() => { ThirdPage = 'Livestream (Facebook)'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Livestream (Facebook)</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio3' id='Video (YouTube)' checked={ThirdPage === 'Video (YouTube)' ? true : false} onChange={() => { ThirdPage = 'Video (YouTube)'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Video (YouTube)</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio3' id='Video (Facebook)' checked={ThirdPage === 'Video (Facebook)' ? true : false} onChange={() => { ThirdPage = 'Video (Facebook)'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Video (Facebook)</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio3' id='History' checked={ThirdPage === 'History' ? true : false} onChange={() => { ThirdPage = 'History'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">History</div></span></label>
                  </div>
                </label>
                <br />

                {/* FOURTH PAGE OPTIONS (SERMONS)*/}
                <label style={{ fontFamily: MainFont, fontSize: '20px', }}>
                  Fourth Page: <br />
                  <div style={{ fontFamily: SubFont, fontSize: '15px' }}>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio4' id='Sermons' checked={FourthPage === 'Sermons' ? true : false} onChange={() => { FourthPage = 'Sermons'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Sermons</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio4' id='Prayer' checked={FourthPage === 'Prayer' ? true : false} onChange={() => { FourthPage = 'Prayer'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Prayer</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radio4' id='History' checked={FourthPage === 'History' ? true : false} onChange={() => { FourthPage = 'History'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">History</div></span></label>
                  </div>
                </label>
                <br />

                {/* YOUTH NAME OPTIONS (FIFTH PAGE) */}
                <label style={{ fontFamily: MainFont, fontSize: '20px' }}>
                  Youth Page Name: <br />

                  <div style={{ fontFamily: SubFont, fontSize: '15px' }}>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioy' id='Youth' checked={YouthName === 'Youth' ? true : false} onChange={() => { YouthName = 'Youth'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Youth</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioy' id='Kids' checked={YouthName === 'Kids' ? true : false} onChange={() => { YouthName = 'Kids'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Kids</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioy' id='Student' checked={YouthName === 'Student' ? true : false} onChange={() => { YouthName = 'Student'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Student</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radioy' id='Remove' checked={YouthName === 'Removed' ? true : false} onChange={() => { YouthName = 'Removed'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Remove this page</div></span></label>
                  </div>
                </label>
                <br />

                {/* GIVING PAGE OPTIONS (SIXTH PAGE) */}
                <label style={{ fontFamily: MainFont, fontSize: '20px' }}>
                  Giving Page: <br />

                  <div style={{ fontFamily: SubFont, fontSize: '15px' }}>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radiog' id='Tithe.ly' checked={SixthPage === 'Tithe.ly' ? true : false} onChange={() => { SixthPage = 'Tithe.ly'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Tithe.ly</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radiog' id='easyTithe' checked={SixthPage === 'easyTithe' ? true : false} onChange={() => { SixthPage = 'easyTithe'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">easyTithe</div></span></label>
                    <label className="sampler-selection"><input type='radio' className='radioButton' name='radiog' id='Removed' checked={SixthPage === 'Removed' ? true : false} onChange={() => { SixthPage = 'Removed'; navigate(); SX = window.scrollX; SY = window.scrollY }} /><span className="checkmark"><div className="sampler-text">Remove this page</div></span></label>
                  </div>
                </label>
                <br />

                {/* SUBMIT BUTTON*/}
                <a target="_blank" href={"https://form.churchsites.online?Color0=" + HomeColor.substring(1) + "&Color1=" + BeliefsColor.substring(1) + "&Color2=" + EventsColor.substring(1) + "&Color3=" + LiveColor.substring(1) + "&Color4=" + SermonsColor.substring(1) + "&Color5=" + YouthColor.substring(1) + "&Color6=" + GivingColor.substring(1) + "&Color7=" + ContactColor.substring(1) + "&Container=" + ContainerChoice + "&Font=" + MainFont + "&Page3=" + ThirdPage + "&Page4=" + FourthPage + "&Page5=" + YouthName + "&Page6=" + SixthPage}>
                  <button style={{ fontFamily: MainFont, paddingBottom: MainFont === 'IM Fell English' || MainFont === 'Bahnschrift' ? '1px' : '5px'}} className="submitButton">Submit</button>
                </a>
                <br />
              </div>
            </div>
          </OutsideClickHandler>
        </div>
      </nav>
    )
  }
}

export default Sampler
