import React from 'react'
import { Link } from 'gatsby'
// import github from '../img/github-icon.svg'
import logo from '../../static/img/mstile-70x70.png'
import { ThirdPage, FourthPage, YouthName, SixthPage } from '../components/Sampler'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand" style={{justifyContent: 'flex-end'}}>
            <Link to="/" className="navbar-item" title="Logo" id="imgLogo">
              <img src={logo} alt="homeLogo" style={{ width: '26px', alignSelf: 'right' }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              style={{marginLeft: '0'}}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" to="/beliefs">
                Beliefs
              </Link>
              <Link className="navbar-item" to="/events">
                Events
              </Link>
              <Link className="navbar-item" to="/livestream">
                {ThirdPage === "Livestream (Facebook)" || ThirdPage === "Livestream (YouTube)" ? "Livestream" : ThirdPage === "Video (Facebook)" || ThirdPage === "Video (YouTube)" ? "Video" : "History"}
              </Link>
              <Link className="navbar-item" to="/sermons">
                {FourthPage}
              </Link>
              {YouthName === "Removed" ? null : 
              <Link className="navbar-item" to="/youth">
                {YouthName}
              </Link>
              }
              {SixthPage === "Removed" ? null : 
              <Link className="navbar-item" to="/giving">
                Giving
              </Link>
              }
              <Link className="navbar-item" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
